import React, { useEffect, useState, useMemo, useRef } from 'react';
import styled from 'styled-components';
import randomColor from 'randomcolor';
import { useDataProvider } from 'react-admin';
import { useSelector, useDispatch } from 'react-redux';
import Timeline, { TimelineHeaders, SidebarHeader, DateHeader } from 'react-calendar-timeline';
import { ArrowLeft, ArrowRight } from '@material-ui/icons';
import 'react-calendar-timeline/lib/Timeline.css'
import moment from 'moment'
import { StarboltState } from '../../types';

import Item from './Item';
import Group from './Group';
import { AnyPtrRecord } from 'dns';

moment.locale('fr')

interface ButtonViewProps {
  isSelected : boolean
}

interface Items {
  group: string
  id: string
  start: string
  end: string
  bgColor: string
}

const TimelineCalendar = ({groups, items} :any) => {

  const [visibleTimeStart, setVisibleTimeStart] : any = useState(moment().startOf("day").valueOf());
  const [visibleTimeEnd, setVisibleTimeEnd] : any = useState(moment().startOf("day").add(1, "day").valueOf());
  const [view, setView] : any = useState('day');
  const [formatDate, setFormatDate] = useState('DD-MM-YYYY')

  const defaultTimeStart = moment()
      .startOf("day")
      .add(1, "day")
      .toDate();
    
  const defaultTimeEnd = moment()
    .startOf("day")
    .add(2, "day")
    .toDate();


    const onPrevClick = () => {
      if(view === "month"){
        const prevStart = moment(visibleTimeStart).startOf("month").subtract(1, 'month').valueOf();
        const prevEnd = moment(visibleTimeEnd).startOf("month").subtract(1, 'month').valueOf();
        setVisibleTimeStart(prevStart);
        setVisibleTimeEnd(prevEnd);
      }
      else if(view === 'year'){
        const prevStart = moment(visibleTimeStart).startOf("year").subtract(1, 'year').valueOf();
        const prevEnd = moment(visibleTimeEnd).startOf("year").subtract(1, 'year').valueOf();
        setVisibleTimeStart(prevStart);
        setVisibleTimeEnd(prevEnd);
      }
      else{
        const zoom = visibleTimeEnd - visibleTimeStart;
        setVisibleTimeStart(visibleTimeStart - zoom)
        setVisibleTimeEnd(visibleTimeEnd - zoom)
      }
    };
  
    const onNextClick = () => {
      if(view === "month"){
        const nextStart = moment(visibleTimeStart).startOf("month").add(1, 'month').valueOf();
        const nextEnd = moment(visibleTimeEnd).startOf("month").add(1, 'month').valueOf();
        setVisibleTimeStart(nextStart);
        setVisibleTimeEnd(nextEnd);
      }
      else if(view === 'year'){
        const prevStart = moment(visibleTimeStart).startOf("year").add(1, 'year').valueOf();
        const prevEnd = moment(visibleTimeEnd).startOf("year").add(1, 'year').valueOf();
        setVisibleTimeStart(prevStart);
        setVisibleTimeEnd(prevEnd);
      }
      else{
        const zoom = visibleTimeEnd - visibleTimeStart;
        setVisibleTimeStart(visibleTimeStart + zoom);
        setVisibleTimeEnd(visibleTimeEnd + zoom);
      }
    };

    const handleClickView = (view : any) => {
      setVisibleTimeStart(moment().startOf(view).valueOf())
      setVisibleTimeEnd(moment().startOf(view).add(1, view).valueOf())
      setView(view);
      if(view === 'month'){
        setFormatDate('MM-YYYY')
      }
      else if(view === 'year'){
        setFormatDate('YYYY');
      }
      else{
        setFormatDate('DD-MM-YYYY')
      }
    }

    var keys = {
      groupIdKey: "id",
      groupTitleKey: "title",
      groupRightTitleKey: "rightTitle",
      itemIdKey: "id",
      itemTitleKey: "title",
      itemDivTitleKey: "title",
      itemGroupKey: "group",
      itemTimeStartKey: "start",
      itemTimeEndKey: "end",
      groupLabelKey: "title"
    };

      if(items.length && groups.length){
        return (
          <div>
            <ContainerButton>
              <ContainerView>
                <ButtonView isSelected={view === 'year'} onClick={()=>handleClickView('year')}>Y</ButtonView>
                <ButtonView isSelected={view === 'month'} onClick={()=>handleClickView('month')}>M</ButtonView>
                <ButtonView isSelected={view === 'week'} onClick={()=>handleClickView('week')}>W</ButtonView>
                <ButtonView isSelected={view === 'day'} onClick={()=>handleClickView('day')}>D</ButtonView>
              </ContainerView>
              <ContainerDateButton>
                <Button onClick={onPrevClick}>
                  <ArrowLeft /> {moment(visibleTimeStart).subtract(1, view).format(formatDate)}
                </Button>
                <div style={{margin : '0 10px'}}>-</div>
                <Button onClick={onNextClick}> 
                  {moment(visibleTimeStart).add(1, view).format(formatDate)} 
                  <ArrowRight/>
                </Button>
              </ContainerDateButton>
            </ContainerButton>
            <Timeline
              groups={groups}
              items={items}
              keys={keys}
              itemsSorted
              itemTouchSendsClick={false}
              stackItems
              itemHeightRatio={0.8}
              showCursorLine
              canMove={false}
              canResize={true}
              defaultTimeStart={defaultTimeStart}
              defaultTimeEnd={defaultTimeEnd}
              visibleTimeStart={visibleTimeStart}
              visibleTimeEnd={visibleTimeEnd}
              itemRenderer={Item}
              groupRenderer={Group}
              sidebarWidth={250}
              onItemClick={()=>{}}
              onTimeChange={(visibleTimeStart : any, visibleTimeEnd : any) => {
                const diff = visibleTimeEnd-visibleTimeStart;
                if(diff === 3600000){
                  return;
                }
                else if(diff === 86400000){
                  setView('day')
                  setFormatDate('DD-MM-YYYY')
                }
                else if(diff < 3000000000 && diff > 2000000000){
                  setView('month')
                  setFormatDate('MM-YYYY')
                }
                else{
                  setView(view)
                }
                setVisibleTimeStart(moment(visibleTimeStart).valueOf())
                setVisibleTimeEnd(moment(visibleTimeEnd).valueOf())
                }
              }
            >
              <TimelineHeaders className="sticky">
                <SidebarHeader>
                  {({ getRootProps } : any) => {
                    return (
                      <Head>
                          <Left>Véhicule</Left>
                          <Middle>Type</Middle>
                          <Right>Status</Right>
                      </Head>
                    )
                  }}
                </SidebarHeader>
                <DateHeader unit={view} />
                <DateHeader />
              </TimelineHeaders>
            </Timeline>
          </div>
        )
      }
      else{
        return (
          <div></div>
        )
      }
}

const Calendar = (props : any) => {
  const dataProvider = useDataProvider();
  const [vehicles, setVehicles] = useState([]);
  const [rides, setRides] = useState([]);
  const state = useSelector((state : StarboltState) => state.starbolt);
    
  useEffect(()=>{
    dataProvider.getList('Ride', {
      pagination : { page : 1, perPage : 1000}, 
      sort : {field : '', order : ''}, 
      filter : { space : state.selectedSpace}
      }).then( ({data} : any) => { 
        let vehiclesGroupbyName:Object = data.reduce((r: any, a: any) => {
          r[a?.vehicle?.name] = [...r[a?.vehicle?.name] || [], a];
          return r;
        }, {});
         const listVehicles: any = Object.entries(vehiclesGroupbyName).map(data => ({title : data[1]?.[0]?.vehicleName, id : data[1]?.[0]?.vehicle?._id, height : 100, type : data[1]?.[0]?.vehicleType}))
         const listRideByVehicles: any = Object.values(vehiclesGroupbyName).map( (data : any) => {
            return data.map((ride : any) => {
              let randomSeed = Math.floor(Math.random() * 1000)
              const color = randomColor({luminosity: 'dark', seed: randomSeed })
              const title = `${ride?.user?.firstName} ${ride?.user?.lastName} ${moment(ride.time.start).format('DD/MM/YYYY')}-${moment(ride.time.end).format('DD/MM/YYYY')}`
              return {group : ride?.vehicle?._id, id : ride._id, start : moment(ride.time.start), end : moment(ride.time.end), title, bgColor : color}
            })
          })
         setVehicles(listVehicles);
         setRides(listRideByVehicles.flat());
        })
      }, [state])
      
  return (
    <Container>
      <TimelineCalendar
        groups={vehicles}
        items={rides}
      />
    </Container>
  );
}

export default Calendar;

const Container = styled.div`
    width : 90%;
    display : block;
    margin-top : 100px;
    .react-calendar-timeline {
      max-width : 1300px;
      border-right: 1px solid #bbb;
      border-left: 1px solid #bbb;
    }

    .rct-header-root {
      background : #272744;
    }

    .react-calendar-timeline .rct-header .rct-label-group {
      background : #272744;
    }

    .react-calendar-timeline .rct-scroll {
      overflow : hidden;
    }
    .rct-item {
      height : 90px !important;
      display: flex;
      flex-direction: row;
      align-items: center;
    }
`;

const Head = styled.div`
  display : flex;
  flex-direction : row;
  width : 250px;
  color : white;
`;

const Left = styled.div`
    flex : 1;
    border-right : 1px solid #bbb;
    display : flex;
    justify-content : center;
    align-items : center;
    text-align : center;
`;
const Middle = styled.div`
    flex : 1;
    border-right : 1px solid #bbb;
    display : flex;
    justify-content : center;
    align-items : center;
    text-align : center;
`;

const Right = styled.div`
    flex : 1;
    display : flex;
    justify-content : center;
    align-items : center;
    text-align : center;
`;

const ContainerButton = styled.div`
    width : 100%;
    max-width : 1300px;
    margin : 10px 0;
    display : flex;
    flex-direction : row;
    justify-content : space-between;
`;

const ContainerDateButton = styled.div`
    display : flex;
    flex-direction : row;
    align-items : center;
    color : #272744;
    font-weight : 600;
    font-size : 16px;
`;

const ContainerView = styled.div`
    display : flex;
    flex-direction : row;
`;

const Button = styled.div`
    width : 110px;
    height : 40px;
    cursor : pointer;
    display : flex;
    flex-direction : row;
    justify-content : center;
    align-items : center;
    background-color : ${(props : any) => props.isSelected ? '#272744' : '#fafafa'};
    color : ${(props : any) => props.isSelected ? 'white' : '#272744'};
    font-weight : 600;
    font-size : 16px;
    border-radius : 4px;
    border : none;
    margin : 0 5px;
`;

const ButtonView = styled.div<ButtonViewProps>`
  width : 40px;
  height : 40px;
  cursor : pointer;
  display : flex;
  flex-direction : row;
  justify-content : center;
  align-items : center;
  background-color : ${(props : any) => props.isSelected ? '#272744' : '#fafafa'};
  color : ${(props : any) => props.isSelected ? 'white' : '#272744'};
  font-weight : 600;
  font-size : 16px;
  border-radius : 4px;
  border : 1px solid #272744;
  margin : 0 5px;
`;



