import { createMuiTheme } from '@material-ui/core/styles';
import red from '@material-ui/core/colors/red';

export const starboltTheme = createMuiTheme({
  palette: {
    primary: {main : '#272744'},
    secondary: {main : '#272744'},
    error: red,
    contrastThreshold: 3,
    tonalOffset: 0.2,
  },
  typography: {
      // Use the system font instead of the default Roboto font.
      fontFamily: [
          '-apple-system',
          'BlinkMacSystemFont',
          '"Segoe UI"',
          'Arial',
          'sans-serif',
      ].join(','),
  },
});

export const starboltAdminTheme = createMuiTheme({
    palette: {
      primary: {main : '#272744'},
      secondary: {main : '#ffe604'},
      error: red,
      contrastThreshold: 3,
      tonalOffset: 0.2,
    },
    typography: {
        // Use the system font instead of the default Roboto font.
        fontFamily: [
            '-apple-system',
            'BlinkMacSystemFont',
            '"Segoe UI"',
            'Arial',
            'sans-serif',
        ].join(','),
    },
  });