import React, { useMemo, useState } from "react";
import StarboltTable from "./style";
import Spinner from "../../../shared/components/Spinner";

const LoadingTable = () => (
  <tbody>
    <tr>
      <td>
        <Spinner size="lg" />
      </td>
    </tr>
  </tbody>
);

type Accessor<T> = keyof T | ((row: T) => string);

type CellProps<T> = {
  value: any;
  row: T;
  index: number;
};

type PaginationOptions = {
  limit: number;
  itemPerPage: number;
};

export type Column<T> = {
  accessor?: Accessor<T>;
  Header: string;
  Cell?: (cellProps: CellProps<T>) => React.ReactNode | string;
};
interface TableProps<T> {
  data: T[];
  columns: Column<T>[];
  sortBy?: (a: T, b: T) => number;
  pagination?: PaginationOptions;
  loading?: boolean;
  onClickRow?: (a?: any) => void; 
}

function factory<T>(data: T[], compareFn?: (a: T, b: T) => number) {
  if (!compareFn) return data;
  return data.slice().sort(compareFn);
}

function Table<T extends { _id: string }>({
  columns,
  data,
  loading,
  sortBy,
  pagination,
  onClickRow
}: TableProps<T>) {
  const filteredData = useMemo(() => factory(data, sortBy), [data, sortBy]);
  const [offset, changeOffset] = useState(0);

  function useNextPage(prevOffset: number) {
    if (!pagination || offset + pagination.itemPerPage >= pagination.limit) {
      return prevOffset;
    }

    return prevOffset + pagination.itemPerPage;
  }

  function usePrevPage(prevOffset: number) {
    if (!pagination || offset - pagination.itemPerPage < 0) {
      return prevOffset;
    }

    return prevOffset - pagination.itemPerPage;
  }

  function usePrevOffset() {
    changeOffset(usePrevPage);
  }

  function useNextOffset() {
    changeOffset(useNextPage);
  }

  return (
    <StarboltTable loading={loading ? 1 : undefined}>
      <table>
        {loading ? (
          <LoadingTable />
        ) : (
          <>
            <thead>
              <tr>
                {columns.map((column, index) => (
                  <th key={`headcell-${index}`}>{column.Header}</th>
                ))}
              </tr>
            </thead>
            <tbody>
              {(filteredData || data)
                .slice(
                  pagination && offset,
                  pagination && pagination.itemPerPage + offset
                )
                .map((row, rowIndex) => (
                  <tr onClick={() => onClickRow && onClickRow(row._id)} key={row._id}>
                    {columns.map((column, index) => (
                      <td key={`cell-${index}`}>
                        {column.Cell
                          ? column.Cell({
                              row,
                              value:
                                typeof column.accessor === "string" &&
                                row[column.accessor],
                              index: rowIndex,
                            })
                          : typeof column.accessor === "string"
                          ? row[column.accessor]
                          : typeof column.accessor === "function" &&
                            column.accessor(row)}
                      </td>
                    ))}
                  </tr>
                ))}
            </tbody>
          </>
        )}
      </table>
    </StarboltTable>
  );
}

export default Table;
