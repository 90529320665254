import React from 'react';
import { useTranslate } from 'react-admin';
import { Record } from 'ra-core';

import QuickEditDialog from '../../../shared/components/QuickEditDialog';
import VehicleForm from '../../vehicles/VehicleForm';

interface VehicleEditButtonProps {
  record? : Record;
  cellClassName? : any;
}

const VehicleEditButton : React.FC<VehicleEditButtonProps> = (props) => {
  const { record } = props;
  const translate = useTranslate();
  return (
    record ?
      <QuickEditDialog
        targetResource="Vehicle"
        title={translate('resources.Parking.vehicleEditTitle')}
        Layout={VehicleForm}
        transform={(data : any) => { 
            return {...data, parking : record.parking}
        }}
        record={record}
        label=""
        /> :
      null
  );
}

export default VehicleEditButton;