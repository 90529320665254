import React from "react";
import styled from "styled-components";

interface Props {
  title: string;
  data: number | string;
  iconPath: any;
}

const CardContainer = styled.div`
  padding        : 18px;
  cursor         : pointer;
  border         : 1px solid rgba(0, 0, 0, 0.2);
  display        : flex;
  flex-direction : column;
  align-items    : center;
  justify-content: center;
`;

const CardBody = styled.div`
  display       : flex;
  flex-direction: column;
  align-items   : center;
`;

const Icon = styled.img`
  width    : max-content;
  max-width: 40px;
`;

const CardContent = styled.div`
  display        : flex;
  flex-direction : column;
  align-items    : center;
  justify-content: center;
  p {
    line-height  : 16px;
    font-size    : 16px;
    margin-bottom: 8px;
    height       : 16px;
    white-space  : nowrap;
    overflow     : hidden;
  }
`;

const Card = ({ iconPath, data, title }: Props) => {
  return (
    <CardContainer>
      <CardBody>
        <Icon src={iconPath} alt="icon" />
        <CardContent>
          <p>{title}</p>
          <p>{data}</p>
        </CardContent>
      </CardBody>
    </CardContainer>
  );
};

export default Card;

