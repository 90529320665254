import styled from "styled-components";

const StarboltTable = styled.div<{ loading?: number }>`
  border-spacing: 0;

  table {
    margin: auto;
    ${({ loading }) => loading && "display: flex;"}
    width: 100%;
    border: 1px solid rgba(0, 0, 0, 0.3);
  }

  thead {
    z-index: 1;
    th {
      background-color: #272744;
      color: white;
    }
  }

  tr:nth-child(even) {
    background-color: #f2f2f2;
  }

  tr:hover {
    background-color: #ddd;
  }

  th {
    position: sticky;
    background-color: white;
    top: 0;
    :last-child {
      border-right: 0;
    }
  }

  tr {
    :last-child {
      td {
        border-bottom: 0;
      }
    }
  }

  td {
    :last-child {
      border-right: 0;
    }
  }

  th,
  td {
    margin: 0;
    padding: 0.5rem;
    border-bottom: 1px solid rgba(0, 0, 0, 0.3);
    border-right: 1px solid rgba(0, 0, 0, 0.3);
  }
`;

export default StarboltTable;
