import React, {useEffect, useState, FC} from 'react';
import { List, Datagrid, TextField, DateField, ReferenceField, useDataProvider, useRefresh, useGetList, useListContext, DatagridBodyProps, FieldProps , Record, userLogout} from 'react-admin';
import { useSelector } from 'react-redux';
import { Row } from "react-flexbox-grid";
import RideCountContainer from "../../shared/containers/RideCountContainer";
import PodiumCard from "../../shared/components/PodiumCard";
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';
import User from '../../shared/entities/User';
import Table, { Column } from "./components/Tables";

interface Props {
  usersList: User[];
}

type DataType = User;

const columns: Column<DataType>[] = [
  {
    Header: "Position",
    Cell: ({ index }) => <div>{index+1}</div>,
  },
  {
    Header: "email",
    accessor: (user) => user.email || "email",
  },
  {
    Header: "Espace",
    accessor: (user) => user.spaces && user.spaces[0] && user.spaces[0].name,
  },
  {
    Header: "Nombre de rides",
    accessor: (user : any) => user.count
  },
];

const Podium = ({ usersList }: Props) => {
  const usersListSorted = usersList.sort((a, b) : any => {
    if(a.count < b.count) return 1;
    if(a.count > b.count) return -1;
  }).slice(0, 10);

  return (
    <div>
      <Row>
        <h1>Top 10</h1>
      </Row>
      <Row>
        <PodiumCard
          position={1}
          user={usersListSorted[1]}
          rideCount={usersListSorted[1]?.count}
        />
        <PodiumCard
          position={0}
          user={usersListSorted[0]}
          rideCount={usersListSorted[0]?.count}
        />
        <PodiumCard
          position={2}
          user={usersListSorted[2]}
          rideCount={usersListSorted[2]?.count}
        />
      </Row>
    </div>
  )
}

const MyDatagrid = ({ usersList}: Props) => {
  const usersListSorted = usersList.sort((a, b) : any => {
    if(a.count < b.count) return 1;
    if(a.count > b.count) return -1;
  }).slice(0, 10);
  
  return (
    <Table data={usersListSorted} columns={columns} />
  )
}


const RankingList = (props : any) => {
  const dataProvider = useDataProvider();
  const refresh      = useRefresh();

  const [ users, setUsers ] : any = useState([]);
  const { selectedSpace } = useSelector((state : any) => state.starbolt)
  const [space, setSpace] = useState('');
  
  useEffect(()=>{
    if(space !== selectedSpace){
      setSpace(selectedSpace);
      setUsers([]);
      dataProvider.getList('User',{
        pagination : { page : 1, perPage : 1000}, 
        sort : {field : '', order : ''},
        filter : { spaces : selectedSpace}})
      .then(async({data} : any) => {
        data.map(async (user : any) => {
          const rides = await dataProvider.getList('Ride', {
            pagination : { page : 1, perPage : 1000}, 
            sort : {field : '', order : ''}, 
            filter : { user: user.id}}
          )
          setUsers((previousState : any) => {
            return [...previousState, {...user, count : rides.data.length}]
          });
  
        })
      })
    }
  }, [selectedSpace])  



  return (
    <>
      <Podium usersList={users} />
      <List
      {...props}
      filter={{spaces : selectedSpace}}
      bulkActionButtons={false}
      title=""
      pagination={false}
      exporter={false}
      >
        <MyDatagrid usersList={users} />
      </List>
    </>
      
  );

}


export default RankingList;