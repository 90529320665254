import React, { useState, useEffect, FC } from "react";
import { TextInput, Edit, TabbedForm, FormTab, SelectInput, required, Datagrid, ReferenceManyField, TextField, EditProps } from "react-admin";
import { makeStyles } from "@material-ui/core/styles";

import VehicleCreateButton from "./components/VehicleCreateButton";
import VehicleEditButton from "./components/VehicleEditButton";
import VehicleDeleteButton from "./components/VehicleDeleteButton";
import Schedule from "./components/Schedule";

const useColumnsStyles = makeStyles(() => ({
  iconColumn: {
    width: "30px",
  },
}));

const ParkingEdit: FC<EditProps> = (props) => {
  const columnClasses = useColumnsStyles();
  const parkingId = props.id;

  return (
    <Edit {...props}>
      <TabbedForm>
        <FormTab label='Informations'>
          <TextInput source='name' />
          <TextInput source='description' />
          <TextInput source='address.formattedAddress' />
        </FormTab>
        <FormTab label='Horaires'>
          <Schedule />
        </FormTab>
        <FormTab label='Véhicules'>
          <TextField source='name' />
          <ReferenceManyField reference='Vehicle' target='parking' filter={{ parking: parkingId }} fullWidth>
            <Datagrid>
              <TextField source='type' />
              <TextField source='name' />
              <TextField source='frameNumber' />
              <TextField source='enabled' />
              <VehicleEditButton cellClassName={columnClasses.iconColumn} />
              <VehicleDeleteButton />
            </Datagrid>
          </ReferenceManyField>
          <VehicleCreateButton />
        </FormTab>
      </TabbedForm>
    </Edit>
  );
};

export default ParkingEdit;
