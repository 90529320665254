import React, { useEffect, useState, useRef } from 'react';
import { AppBar, useRefresh } from 'react-admin';
import { MenuItem, MenuList, useMediaQuery, ListItemIcon, Divider, Theme, Button, Popper, Grow, Paper, ClickAwayListener } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { useDataProvider, useRedirect } from 'react-admin';
import { useSelector, useDispatch } from 'react-redux';
import { themeUpdated } from '../store/actions/theme';
import { StarboltState } from '../types';

import { resourceLoaded , spaceSelected, parkingsSelected} from '../store/actions/account';


import StarboltUserMenu from './UserMenu';

const useLayoutStyle = makeStyles(
  theme => ({
    version : {
      position : 'fixed',
      bottom   : 0,
      right    : 0,
      padding  : theme.spacing(1),
      color    : '#777',
      fontSize : '10px'
    },
    menuItem: {
        color: theme.palette.text.secondary,

      },
    icon: {
      minWidth: theme.spacing(5)
    },
    paper: {
      marginRight: theme.spacing(2),
    },
  })
);

const StarboltAppBar = (props : any) => {
  const [spaces, setSpaces] = useState([]);
  const [showMenu, setShowMenu] = useState(false);
  const [space, setSpace] : any = useState({});
  const [isAdmin, setIsAdmin] = useState(false);
  const [open, setOpen] = useState(false);
  const anchorRef: any= useRef(null);
  const theme = useSelector((state: StarboltState) => state.starbolt.theme)

  const dataProvider = useDataProvider();
  const dispatch = useDispatch();
  const refresh = useRefresh();
  const classes = useLayoutStyle();
  const redirect = useRedirect();

  const userSpace: any = localStorage.getItem('space');
  const admin = localStorage.getItem('permissions') === "Admin";
  const accountId = localStorage.getItem('accountId');
  const parking : any = localStorage.getItem('parking');


  useEffect(() => {
    redirect('/Dashboard');
    if(!admin){
      dataProvider.getOne('Space', {id : userSpace}).then(({data} : any)=> {
        setSpace(data)
        const parkingIds = data?.parkings.map((parking : any) => parking.id)
        dispatch(resourceLoaded({space : userSpace, accountId, admin : !!admin, parkings : parkingIds, theme : "" }));
      })
    } else if(admin){

      setIsAdmin(true);

      dataProvider.getList('Space', {pagination : { page : 1, perPage : 1000}, sort : {field : '', order : ''}, filter : ""})
      .then(({ data } : any) => {
          setSpaces(data);
          if(space?.id){
            setSpace(data.filter((space : any) => space.id === userSpace)[0]);
          }
          else{
            setSpace(data[0]);
            const parkings = data[0]?.parkings.map((parking : any) => parking.id) 
            dispatch(resourceLoaded({space : data[0]?.id, accountId, admin : !!admin, parkings, theme : "" }));
          }
      })
      .catch((error : any) => {
          console.log(error)
      })
    }
  }, []);


  const makeSelectHandler = (id : string) => () => {
    dispatch(spaceSelected(id));
    const space : any = spaces.filter((space : any) => space.id === id)[0];
    setSpace(space)
    const parkingIds = space?.parkings?.map((parking : any) => parking.id) || ''
    dispatch(parkingsSelected(parkingIds));
    dispatch(themeUpdated(""))
    if(theme === "Admin"){
      redirect('/Dashboard');
    }
  }

  const handleChangeViewAdmin = () => {
    dispatch(themeUpdated("Admin"));
    redirect('/Space');
  }


  return(
  <AppBar
    {...props}
    userMenu={<StarboltUserMenu spaces={spaces} isAdmin={isAdmin} onSelect={makeSelectHandler} onChangeAdminTheme={handleChangeViewAdmin}/>}
    >
      <div style={{flex : 1}}></div>
      {
        theme !== "Admin" ?
        space?.name : null
      }

    </AppBar>

  )
}

export default StarboltAppBar;