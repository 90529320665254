import React from 'react';
import { useState } from 'react';
import { useSelector } from 'react-redux';
import { useTranslate, MenuItemLink } from 'react-admin';
import SubMenu from './SubMenu';
import { StarboltState } from '../types';

import { resourcesUser, resourcesUserAdmin, resourcesAdmin, isLeaf, isHidden, ResourcesDefinition, BranchType} from '../resources';

let initialState : MenuState;
const loadInitialState = () => {
  if(!initialState) {
    try {
      initialState = JSON.parse(localStorage.getItem('menu-state') || '{}');
    }
    catch(e) {
      initialState = {};
    }
  }

  return initialState;
};

const Menu : React.FC<MenuProps>= ({ onMenuClick, dense }) => {
  const translate = useTranslate();

  const [state, setState] = useState<MenuState>(loadInitialState());
  const theme = useSelector((state : StarboltState) => state.starbolt.theme);
  const handleToggle = (menu : string) => {
    setState(state => {
      const newState = { ...state, [menu]: !state[menu] };
      localStorage.setItem('menu-state', JSON.stringify(newState));
      return newState;
    });
  };

  const permissions = localStorage.getItem("permissions");
  const resources = permissions !== "Admin" ? resourcesUser : theme === "Admin" ? resourcesAdmin : resourcesUserAdmin;

  const open : boolean = !!useSelector((state : any) => state.admin.ui.sidebarOpen);

  const buildResourceTree = (resources : ResourcesDefinition, basePath = "resources") => resources.map((node, index:number) => {
    const name = node.name;
    const nodeName = `${basePath}.${name}`;


    if (isHidden(node)) {return null;}

    return isLeaf(node) ?
      <MenuItemLink
          to={`/${name}`}
          primaryText={translate(nodeName, {
              smart_count: 2,
          })}
          leftIcon={node.icon || <span>IC</span>}
          onClick={onMenuClick}
          sidebarIsOpen={open}
          dense={dense}
          key={`resource_${index}`}
      /> :
      <SubMenu
        handleToggle={() => handleToggle(nodeName)}
         isOpen={state[nodeName]}
         sidebarIsOpen={open}
         name={nodeName}
         icon={node.icon || <span>"IC"</span>}
         dense={dense}
         >
         {
           buildResourceTree((node as BranchType).children, nodeName)
         }
      </SubMenu>

  });

  return (
    <div style={{marginTop : '120px'}} >
      {
        buildResourceTree(resources)
      }
    </div>
  );
}

interface MenuProps {
  onMenuClick : () => void;
  dense : boolean;
}

type MenuState = {
  [name : string] : boolean;
}

export default Menu;