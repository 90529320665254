import { Record } from "ra-core";
import jwt from "jsonwebtoken";
import config from "./configprovider";
import getStore from "../store";
import history from "../history";
import { login, logout } from "../store/actions/account";

import { apiObjectToFront, frontObjectToApi } from "./services/accounts";
import canI from "./cani";

import { FIRST_CONNECTION } from "../shared/constants/routes";
import { STORE_PREFIX } from "../shared/constants/redux";

interface Credentials {
  username: string;
  password: string;
}

interface Error {
  status: number;
}

const redirectIfPristine = (account: Record) => {
  if (account?.meta?.pristine === "true") {
    history.replace(FIRST_CONNECTION);
  }
};

/*
 Auth provider object
*/
const authProvider = {
  login: async ({ username = "", password }: Credentials) => {
    const request = new Request(`${config("ACCOUNTS_API_URL")}/logins`, {
      method: "POST",
      headers: new Headers({
        "Content-Type": "application/json",
      }),
      body: JSON.stringify({
        method: "email",
        data: {
          email: username.trim().toLowerCase(),
          password,
        },
      }),
    });

    const response = await fetch(request);

    if (response.status === 401) {
      throw new Error("login.wrongCredentials");
    }

    if (response.status === 403) {
      throw new Error("login.accountMissing");
    }

    if (response.status < 200 || response.status >= 300) {
      throw new Error(response.statusText);
    }

    const account = apiObjectToFront(await response.json());

    localStorage.setItem("token", account.token);
    localStorage.setItem("accountId", account.accountId);

    getStore().dispatch(login(account));

    redirectIfPristine(account);
  },

  logout: async () => {
    localStorage.removeItem("token");
    localStorage.removeItem("permissions");
    localStorage.removeItem("accountId");
    localStorage.removeItem("space");
    getStore().dispatch(logout());
  },

  checkAuth: async (force = false) => {
    const state = getStore().getState();
    let account = state?.[STORE_PREFIX]?.account;
    const accountId = account?.id;
    const token = localStorage.getItem("token");

    if (!token) {
      return Promise.reject();
    }

    if (token && (force || !accountId)) {
      const request = new Request(`${config("ACCOUNTS_API_URL")}/me`, {
        method: "GET",
        headers: new Headers({
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        }),
      });

      const response = await fetch(request);

      if (response.status < 200 || response.status >= 300) {
        localStorage.removeItem("token");
        localStorage.removeItem("permissions");
        localStorage.removeItem("accountId");
        localStorage.removeItem("space");
        getStore().dispatch(logout());

        return Promise.reject();
      }

      account = apiObjectToFront(await response.json());

      getStore().dispatch(login(account));
    }

    return Promise.resolve();
  },

  checkError: async (error: Error) => {
    const status = error.status;

    if (status === 401 || status === 403) {
      localStorage.removeItem("token");
      return Promise.reject();
    }

    return Promise.resolve();
  },

  getPermissions: async () => {
    const token = localStorage.getItem("token") || "";
    const tokenPayload = jwt.decode(token) as { roles?: string[]; accountId?: string; resources?: any };

    if (tokenPayload?.roles?.includes("Admin")) {
      localStorage.setItem("permissions", "Admin");
    }
    if (tokenPayload?.resources?.space) {
      const space = tokenPayload.resources.space[0];
      if (space) {
        localStorage.setItem("space", space);
      }
    } else {
      const request = new Request(`${config("MODEL_API_URL")}/User?accountId=${tokenPayload?.accountId}`, {
        method: "GET",
        headers: new Headers({
          "Content-Type": "application/json",
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        }),
      });
      const response = await fetch(request);

      if (response.status < 200 || response.status >= 300) {
        throw new Error(response.statusText);
      } else {
        const responseJson = apiObjectToFront(await response.json());
        const space = responseJson.data[0]?.spaces[0]?.id;
        if(space){
          localStorage.setItem("space", space);
        }
      }
    }
    return canI({ roles: tokenPayload?.roles || [] });
  },

  changePassword: async (password: string) => {
    const state = getStore().getState();
    const accountId = state?.[STORE_PREFIX]?.account?.id;

    const request = new Request(`${config("ACCOUNTS_API_URL")}/email-credentials/change`, {
      method: "POST",
      headers: new Headers({
        "Content-Type": "application/json",
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      }),
      body: JSON.stringify({ accountId, password }),
    });

    const response = await fetch(request);

    if (response.status < 200 || response.status >= 300) {
      throw new Error(response.statusText);
    }
  },

  resetPassword: async (username: string) => {
    const request = new Request(`${config("ACCOUNTS_API_URL")}/email-credentials/lost`, {
      method: "POST",
      headers: new Headers({
        "Content-Type": "application/json",
      }),
      body: JSON.stringify({ email: username.trim().toLowerCase() }),
    });

    const response = await fetch(request);

    if (response.status < 200 || response.status >= 300) {
      throw new Error(response.statusText);
    }
  },

  updateProfile: async (profile: Record) => {
    const apiProfile = frontObjectToApi(profile);

    const request = new Request(`${config("ACCOUNTS_API_URL")}/me`, {
      method: "PATCH",
      headers: new Headers({
        "Content-Type": "application/json",
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      }),
      body: JSON.stringify(apiProfile),
    });

    const response = await fetch(request);

    if (response.status < 200 || response.status >= 300) {
      throw new Error(response.statusText);
    }

    return await response.json();
  },

  getIdentity: async () => {
    const state = getStore().getState();
    const id = state?.[STORE_PREFIX]?.account?.id;
    const fullName = (state?.[STORE_PREFIX]?.account?.meta?.firstName || "") + " " + (state?.[STORE_PREFIX]?.account?.meta?.lastName || "");
    return { id, fullName };
  },
};

export default authProvider;
