import React, { useState, useEffect } from 'react';
import { MenuItem, useMediaQuery, ListItemIcon, Divider, Theme } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { UserMenu } from 'react-admin';

const useLayoutStyle = makeStyles(
  theme => ({
    version : {
      position : 'fixed',
      bottom   : 0,
      right    : 0,
      padding  : theme.spacing(1),
      color    : '#777',
      fontSize : '10px'
    },
    menuItem: {
        color: theme.palette.text.secondary,
    },
    icon: {
      minWidth: theme.spacing(5)
    }
  })
);

const StarboltUserMenu = (props : any) => {
  const { spaces=[], onSelect, isAdmin, onChangeAdminTheme } = props;
  const isXSmall = useMediaQuery((theme : Theme) =>
    theme.breakpoints.down('xs')
  );

  const classes = useLayoutStyle();

  return (
    <UserMenu {...props}>
      {
        isAdmin && spaces.map(
          (space : any, index:number) =>
          <div key={`${space.id}_${index}`}>
            <MenuItem
              key={space.id}
              className={classes.menuItem}
              component={isXSmall ? 'span' : 'li'}
              onClick={onSelect(space.id)}
              >
                <div>
                    {space.name}
                </div>
              </MenuItem>
          </div>
        )
      }
      {
        isAdmin &&
        <div>
          <MenuItem
            className={classes.menuItem}
            component={isXSmall ? 'span' : 'li'}
            onClick={onChangeAdminTheme}
          >
            <div>Administration</div>
          </MenuItem>
        </div>
      }
    </UserMenu>
  );
}

export default StarboltUserMenu;