import * as React from 'react';
import { FunctionComponent, useState } from 'react';
import PropTypes from 'prop-types';
import { Field, Form } from 'react-final-form';
import CardActions from '@material-ui/core/CardActions';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import CircularProgress from '@material-ui/core/CircularProgress';
import { makeStyles, Theme } from '@material-ui/core/styles';
import { useTranslate, useLogin, useNotify, useSafeSetState, useAuthProvider } from 'ra-core';

interface Props {
    redirectTo?: string;
}

interface FormData {
    username: string;
    password: string;
}

const useStyles = makeStyles(
    (theme: Theme) => ({
        form: {
            padding: '0 1em 1em 1em',
        },
        input: {
            marginTop: '1em',
        },
        button: {
            width: '100%',
        },
        icon: {
            marginRight: theme.spacing(1),
        },
        lostContainer : {
          textAlign : 'right',
          paddingTop : '10px',
          color : theme.palette.primary.dark
        },
        lost : {
          textDecoration : 'underline',
          cursor : 'pointer'
        },
        executed : {
          padding: '1em 0',
          textAlign : 'justify'
        }
    }),
    { name: 'RaLoginForm' }
);

const Input = ({
    // @ts-ignore
    meta: { touched, error }, // eslint-disable-line react/prop-types
    // @ts-ignore
    input: inputProps, // eslint-disable-line react/prop-types
    ...props
}) => (
    <TextField
        error={!!(touched && error)}
        helperText={touched && error}
        {...inputProps}
        {...props}
        fullWidth
    />
);

const LoginForm: FunctionComponent<Props> = props => {
    const { redirectTo } = props;
    const [loading, setLoading] = useSafeSetState(false);
    const login = useLogin();
    const authProvider = useAuthProvider();
    const translate = useTranslate();
    const notify = useNotify();
    const classes = useStyles(props);
    const [lost, setLost] = useState(false)
    const [lostExecuted, setLostExecuted] = useState(false);

    const handleLostClick = () => {
      setLost(!lost);
      setLostExecuted(false);
    };

    const validate = (values: FormData) => {
        const errors : {username : string | undefined, password : string | undefined} = { username : undefined, password : undefined};

        if (!values.username) {
            errors.username = translate('ra.validation.required');
        }
        if (!values.password) {
            errors.password = translate('ra.validation.required');
        }
        return errors;
    };

    const submit = (values : any) => {
        setLoading(true);
        if (lost) {
          authProvider.resetPassword(values.username)
            .then(() => {
                setLoading(false);
                setLostExecuted(true);
            })
            .catch((error:any) => {
                setLoading(false);
                notify(
                    typeof error === 'string'
                        ? error
                        : typeof error === 'undefined' || !error.message
                        ? 'ra.auth.sign_in_error'
                        : error.message,
                    'warning'
                );
            });
        }
        else {
          login(values, redirectTo)
              .then(() => {
                  setLoading(false);
              })
              .catch(error => {
                  setLoading(false);
                  notify(
                      typeof error === 'string'
                          ? error
                          : typeof error === 'undefined' || !error.message
                          ? 'ra.auth.sign_in_error'
                          : error.message,
                      'warning'
                  );
              });
        }
    };

    return (
        <Form
            onSubmit={submit}
            validate={validate}
            render={({ handleSubmit }) => (
                <form onSubmit={handleSubmit} noValidate>
                    <div className={classes.form}>
                        <div className={classes.input}>
                        {
                          lostExecuted ?
                            <div className={classes.executed}>
                              {translate('login.lostExecuted')}
                            </div> :
                            <Field
                                autoFocus
                                id="username"
                                name="username"
                                component={Input}
                                label={translate('ra.auth.username')}
                                disabled={loading}
                            />
                        }
                        </div>
                        {
                          !lost ?
                            <div className={classes.input}>
                                <Field
                                    id="password"
                                    name="password"
                                    component={Input}
                                    label={translate('ra.auth.password')}
                                    type="password"
                                    disabled={loading}
                                    autoComplete="current-password"
                                />
                            </div> : null
                        }
                        <div
                          className={classes.lostContainer}
                        >
                          <span
                          onClick={handleLostClick}
                          className={classes.lost}
                          >
                          {translate(lost ? 'login.gotoLogin' : 'login.gotoLost')}
                          </span>
                        </div>
                    </div>
                    <CardActions>
                        <Button
                            variant="contained"
                            type="submit"
                            color="primary"
                            disabled={loading || lostExecuted}
                            className={classes.button}
                        >
                            {loading && (
                                <CircularProgress
                                    className={classes.icon}
                                    size={18}
                                    thickness={2}
                                />
                            )}
                            {translate(lost ? 'login.lostButton' : 'ra.auth.sign_in')}
                        </Button>
                    </CardActions>
                </form>
            )}
        />
    );
};

LoginForm.propTypes = {
    redirectTo: PropTypes.string,
};

export default LoginForm;