import React from 'react';
import { useTranslate, useRefresh, ButtonProps } from 'react-admin';
import { Record } from 'ra-core';

import QuickCreateDialog from '../../../shared/components/QuickCreateDialog';
import VehicleForm from '../../vehicles/VehicleForm';
import { FormTab } from 'react-admin';
import VehicleCreate from '../../vehicles/VehicleCreate';

interface VehicleCreateButtonProps {
  record? : Record
}

const VehicleCreateButton : React.FC<VehicleCreateButtonProps> = (props : any) => {
  const { record } : any = props;
  const translate = useTranslate();
  const refresh = useRefresh();

  return (
    
      <QuickCreateDialog
        targetResource="Vehicle"
        title={translate('resources.Parking.vehicleCreateTitle')}
        Layout={VehicleForm}
        transform={(data : any) => ({...data, parking : record.id})}
        onChange={refresh}
        /> 
  );
}

export default VehicleCreateButton;