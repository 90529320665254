import React, {useEffect, useState, FC} from 'react';
import { List, Datagrid, TextField, useDataProvider, ListProps, FieldProps } from 'react-admin';
import { useSelector } from 'react-redux';
import User from '../../shared/entities/User';
import { StarboltState } from '../../types';

const RideCount: FC<FieldProps> = ({record}: any) => {
  const dataProvider = useDataProvider();
  const { id } = record;
  const [count, setCount] = useState();
  
  useEffect(()=>{
    dataProvider.getList('Ride', {
        pagination : { page : 1, perPage : 1000}, 
        sort : {field : '', order : ''}, 
        filter : { user: id}})
        .then(({data} :any)=> setCount(data.length))
  }, [])

  return(
    <span>
      <div>{count}</div>
    </span>
  )
}

const UsersList: FC<ListProps> = (props) => {
  const state = useSelector((state: StarboltState) => state.starbolt);
  RideCount.defaultProps = { label : 'Nombre de rides'}
  return (
    <List
      {...props}
      bulkActionButtons={false}
      title=""
      filter={{spaces : state.selectedSpace}}
    >
      <Datagrid>
        <TextField source="email" />
        <TextField source="spaces[0].name" />
        <RideCount />
      </Datagrid>
    </List>
  );

}


export default UsersList;