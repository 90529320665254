import React, { FC } from "react";
import { List, Datagrid, TextField, DateField, ReferenceField, ListProps } from "react-admin";
import { useSelector } from "react-redux";

const UsersAdminList: FC<ListProps> = (props) => {
  const state = useSelector((state: any) => state.starbolt);

  return (
    <List {...props} bulkActionButtons={false} title='' pagination={false}>
      <Datagrid>
        <TextField source='email' />
        <TextField source='firstName' label='Prénom' />
        <TextField source='lastName' label='Nom' />
        <ReferenceField label='Espace' source='spaces[0]' reference='Space'>
          <TextField source='name' />
        </ReferenceField>
      </Datagrid>
    </List>
  );
};

export default UsersAdminList;
