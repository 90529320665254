import { applyMiddleware, combineReducers, compose, createStore } from 'redux';
import { routerMiddleware, connectRouter } from 'connected-react-router';
import createSagaMiddleware from 'redux-saga';
import { all, fork } from 'redux-saga/effects';
import {
    adminReducer,
    adminSaga,
    USER_LOGOUT,
} from 'react-admin';
import {
    AuthProvider,
    DataProvider,
    I18nProvider,
    InitialState,
    ReduxState
} from 'ra-core';

import starboltReducers from './store/reducers';
import starboltSagas from './store/sagas';

interface Window {
    __REDUX_DEVTOOLS_EXTENSION_COMPOSE__?: (traceOptions: object) => Function;
}

interface Params {
    dataProvider: any; // DataProvider
    history: History;
    authProvider: AuthProvider;
    customReducers?: any;
    customSagas?: any[];
    i18nProvider?: I18nProvider;
    initialState?: InitialState;
    locale?: string;
}

let store : ReduxState; // Global Redux store;

const createAdminStore = ({
    authProvider,
    dataProvider,
    history
} : Params) => {
    const reducer = combineReducers({
        admin: adminReducer,
        router: connectRouter(history),
        starbolt : starboltReducers
        // add your own reducers here
    });

    const resettableAppReducer = (state : any, action : {type : string}) =>
        reducer(
            action.type !== USER_LOGOUT
                ? state
                : // Erase data from the store but keep location, notifications, ui prefs, etc.
                  // This allows e.g. to display a notification on logout
                  {
                      ...state,
                      admin: {
                          ...state.admin,
                          loading: 0,
                          resources: {},
                          customQueries: {},
                          references: { oneToMany: {}, possibleValues: {} },
                      },
                  },
            action
        );

    const saga = function* rootSaga() {
        yield all(
            [
                adminSaga(dataProvider, authProvider),
                starboltSagas
                // add your own sagas here
            ].map(fork)
        );
    };
    const sagaMiddleware = createSagaMiddleware();
    const typedWindow = typeof window !== 'undefined' && (window as Window);

    const composeEnhancers =
        (process.env.NODE_ENV === 'development' &&
            typedWindow &&
            typedWindow.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ &&
            typedWindow.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__({
                trace: true,
                traceLimit: 25,
            })) ||
        compose;

    store = createStore(
        resettableAppReducer,
        { /* set your initial state here */ },
        composeEnhancers(
            applyMiddleware(
                sagaMiddleware,
                routerMiddleware(history),
                // add your own middlewares here
            ),
            // add your own enhancers here
        ),
    );
    sagaMiddleware.run(saga);

    return store;
};

const getStore = () => store;

export default getStore;
export {
  createAdminStore
}