import { put, takeEvery, call } from 'redux-saga/effects';
import decodeJwt from 'jwt-decode';
// import { client } from '../../providers/authProvider';
import { PARKING_LIST } from '../../graphql/queries';

import { ADMIN_LOGIN, USER_LOGIN, resourceLoaded, spaceSelected } from '../actions/account';
import { refreshView } from '../actions/react-admin';
// import { ESTABLISHMENTS } from '../../shared/constants/choices';

function* loadUserData(action : {type : string, payload : any}) {
  const { payload } = action;
  const { space, accountId, parking, admin } : any = payload;

 
  yield put(resourceLoaded({parkings : parking.parkingList, space, accountId, admin}))
  yield put(refreshView())
}

function loadAdminData(action : {type : string, payload : any}) {
  const { payload } = action;
  const dataToken : string = decodeJwt(payload);
  const { _id } : any = dataToken;
}


export default function* initSaga() {
    // yield takeEvery(ADMIN_LOGIN, loadAdminData);
    // yield takeEvery(USER_LOGIN, loadUserData);
}