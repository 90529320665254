import { modelDataProvider } from "./services/model";
import { accountsDataProvider } from "./services/accounts";
import { DataProvider } from "ra-core";

const services: { [key: string]: DataProvider } = {
  accounts: accountsDataProvider,
};

const getServiceDataProvider = (resource: string): DataProvider => services[resource] || modelDataProvider;

const dataProvider: DataProvider = {
  getList: async (resource, params) => getServiceDataProvider(resource).getList(resource, params),

  getOne: async (resource, params) => getServiceDataProvider(resource).getOne(resource, params),

  getMany: async (resource, params) => getServiceDataProvider(resource).getMany(resource, params),

  getManyReference: async (resource, params) => getServiceDataProvider(resource).getManyReference(resource, params),

  create: async (resource, params) => getServiceDataProvider(resource).create(resource, params),

  update: async (resource, params) => getServiceDataProvider(resource).update(resource, params),

  updateMany: async (resource, params) => getServiceDataProvider(resource).updateMany(resource, params),

  delete: async (resource, params) => getServiceDataProvider(resource).delete(resource, params),

  deleteMany: async (resource, params) => getServiceDataProvider(resource).deleteMany(resource, params),
};

export default dataProvider;
