import React, { FC } from 'react';
import { TextInput, Edit, SimpleForm, TabbedForm, FormTab, BooleanInput, EditProps } from 'react-admin';

const VehicleEdit: FC<EditProps> = props => {
    return (
        <Edit {...props}>
            <TabbedForm>
                <FormTab label='description'>
                    <TextInput source="name"/>
                    <TextInput source="description"/>
                    <TextInput source="type" defaultValue="BIKE" disabled={false}/>
                    <BooleanInput source="enabled"/>
                </FormTab>
                <FormTab label="Sécurité">
                    <TextInput source="serialNumber"/>
                    <TextInput source="frameNumber"/>
                    <TextInput source="insuranceNumber"/>
                </FormTab>
                <FormTab label="Cadenas">
                    <TextInput source="lock.type" defaultValue="LINKA" disabled={true}/>
                    <TextInput source="lock.code" />
                    <TextInput source="lock.macAddress" />
                </FormTab>
            </TabbedForm>
        </Edit>
    )
}

export default VehicleEdit;