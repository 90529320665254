import {
    LOGOUT,
    RESOURCES_LOADED,
    PARKINGS_SELECTED
  } from '../actions/account';
  
  const initialState = null;
  
  export default (previousState = initialState, action) => {
    switch(action.type) {
      case RESOURCES_LOADED: {   
           return action.payload.parkings
      }
      case PARKINGS_SELECTED: {
        return action.payload;
      }
      case LOGOUT: {
        return initialState;
      }
      default:
        return previousState;
    }
  };