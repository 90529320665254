import { DataProvider } from "ra-core";

import config from "./../configprovider";
import createDefaultDataProvider from "./default";


export const modelDataProvider: DataProvider = createDefaultDataProvider(
  config("MODEL_API_URL"), 30
);

