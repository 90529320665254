import React, { useState, FC} from 'react';
import { TextInput, Edit, SimpleForm, TabbedForm, FormTab, BooleanInput, EditProps } from 'react-admin';
import styled from 'styled-components';
import Schedule from './components/Schedule';

import RichTextInput from 'ra-input-rich-text';

const SpaceEdit: FC<EditProps> = props => {
    return (
        <Edit {...props} >
            <TabbedForm redirect="list">
                <FormTab label='Informations'>
                    <TextInput source="name"/>
                    <TextInput source="description"/>
                    <TextInput source="code"/>
                    <TextInput source="address.formattedAddress"/>
                </FormTab>
                <FormTab label="Horaires">
                  <Schedule />
                </FormTab>
                <FormTab label="CGV">
                  <BooleanInput source='hasTermsAndConditions' label='CGV' />
                  <RichTextInput source='termsAndConditions' /* multiline source='termsAndConditions' style={{width : 600, height : 400 }} label="Termes et conditions" */ />
                </FormTab>
            </TabbedForm>
        </Edit>
    )
}

export default SpaceEdit;

const TextArea = styled(TextInput)`
    overflow: scroll;
    textarea {
        min-height : 250px !important
    }
`;