import {
    USER_LOGIN,
    ADMIN_LOGIN,
    RESOURCES_LOADED,
    LOGOUT
  } from '../actions/account';
  
  const initialState = {};
  
  export default (previousState = initialState, action) => {
    switch(action.type) {
      case RESOURCES_LOADED: return {        
            ...previousState,
            id : action.payload.accountId,
            admin : action.payload.admin
      }
      case LOGOUT: {
        return initialState;
      }
      default:
        return previousState;
    }
  };