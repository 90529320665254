import React, { FC } from 'react';
import { List, Datagrid, TextField, DateField, ReferenceField, ListProps } from 'react-admin';


const SpacesList: FC<ListProps> = props =>{  
  return (
    <List
      {...props}
      bulkActionButtons={false}
      title=""
      pagination={false} 
    >
      <Datagrid rowClick="edit">
        <TextField source="name" label="Nom de l'espace"/>
        <TextField source="description" />
        <TextField source="code" />
        <TextField source="address.formattedAddress" label="Adresse" />
      </Datagrid>
    </List>
  );

}


export default SpacesList;