import React, { Fragment, useState } from 'react';

import { Button, useUpdate, useNotify, FormWithRedirect, SaveButton } from 'react-admin';
import { Record } from 'ra-core';
import { useForm } from 'react-final-form'
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
import IconCancel from '@material-ui/icons/Cancel';
import EditIcon from '@material-ui/icons/Edit';

const requiredProp = (name : string) =>
  console.error(`QuickEditDialog requires a ${name} prop`);



const QuickEditDialog : React.FC<QuickEditDialogProps> = (props) => {
  const {
    Layout,
    onChange,
    targetResource,
    ariaLabel = '',
    title = '',
    target,
    label = 'ra.action.edit',
    transform = data => data,
    record
  } = props;

  if (!targetResource) { requiredProp('targetResource'); }
  if (!Layout)         { requiredProp('Layout'); }

  const [visible, setVisible] = useState(false);
  const [update, {loading}] = useUpdate(targetResource, record.id);
  const notify = useNotify();
  const form = useForm();

  const handleShow = () => setVisible(true);
  const handleHide = () => setVisible(false);

  const _handleSubmit = (values : any) => {
    update({payload : {data : transform(values)}}, {
      onSuccess : ({data} : {data : Record}) => {
        handleHide();

        target && form.change(target, data.id);

        onChange && onChange(data);
      },
      onFailure: (error : any) => notify(`Resource update error: ${error.message}`, 'warning')
    })
  };

  return (
    <Fragment>
      <Button
        className={`quick-update-dialog-button`}
        onClick={handleShow}
        label={label}
      >
        <EditIcon />
      </Button>
      <Dialog
        fullWidth
        open={visible}
        onClose={handleHide}
        aria-label={ariaLabel || title}
      >
        <DialogTitle>{title}</DialogTitle>
        <FormWithRedirect
          resource={targetResource}
          save={_handleSubmit}
          initialValues={record}
          render={({
              handleSubmitWithRedirect,
              pristine,
              saving,
              record
          }) : any => (
              <>
                  <DialogContent>
                      <Layout />
                  </DialogContent>
                  <DialogActions>
                      <Button
                          label="ra.action.cancel"
                          onClick={handleHide}
                          disabled={loading}
                      >
                          <IconCancel />
                      </Button>
                      <SaveButton
                          handleSubmitWithRedirect={
                              handleSubmitWithRedirect
                          }
                          /* pristine={pristine} */
                          saving={saving}
                          disabled={loading}
                      />
                  </DialogActions>
              </>
          )}
        />
      </Dialog>
    </Fragment>
  );
};

export interface QuickEditDialogProps {
  Layout : React.FC;
  onChange? : (data : any) => void;
  targetResource : string;
  ariaLabel? : string;
  title : string;
  target? : string;
  label? : string;
  transform? : (data : Record) => Record;
  record : Record
}

export default QuickEditDialog;