import React from 'react';
import { DeleteButton, useTranslate, DeleteButtonProps } from 'react-admin';
import { Record } from 'ra-core';

interface VehicleDeleteButtonProps {
  record? : Record;
  cellClassName? : any;
}

const VehicleDeleteButton: React.FC<DeleteButtonProps> = props => {
  const { record } = props;
  const translate = useTranslate();

  return (
    record ?
      <DeleteButton
        undoable={false}
        label=""
        redirect={false}
        title={translate('resources.Parking.vehicleDeleteTitle')}
        {...props}
        /> :
      null
  );
}

export default VehicleDeleteButton;