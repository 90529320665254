import React, { FC } from 'react';
import { BooleanInput } from 'react-admin';
import { TextInput, Create, TabbedForm, FormTab, CreateProps } from 'react-admin';

const VehicleCreate: FC<CreateProps> = props => {
    return (
        <Create {...props} >
            <TabbedForm redirect="list">
                <FormTab label='description'>
                    <TextInput source="name"/>
                    <TextInput source="description"/>
                    <TextInput source="type" defaultValue="BIKE" disabled={false}/>
                    <BooleanInput source="enabled"/>
                </FormTab>
                <FormTab label="Sécurité">
                    <TextInput source="serialNumber"/>
                    <TextInput source="frameNumber"/>
                    <TextInput source="insuranceNumber"/>
                </FormTab>
                <FormTab label="Cadenas">
                    <TextInput source="lock.type" defaultValue="LINKA" disabled={true}/>
                    <TextInput source="lock.code" />
                    <TextInput source="lock.macAddress" />
                </FormTab>
            </TabbedForm>
        </Create>
    )
}

export default VehicleCreate;