import { TranslationMessages } from 'ra-core';
import frenchMessages from 'ra-language-french';

const customMessages : TranslationMessages = {
    ...frenchMessages,
    "0" : "0",
    "1" : "1",
    "2" : "2",
    "3" : "3",
    "4" : "4",
    "5" : "5",
    "6" : "6",
    "7" : "7",
    "8" : "8",
    "9" : "9",
    "10" : "10",
    "11" : "11",
    "12" : "12",
    "13" : "13",
    "14" : "14",
    "15" : "15",
    "16" : "16",
    "17" : "17",
    "18" : "18",
    "19" : "19",
    "20" : "20",
    "21" : "21",
    "22" : "22",
    "23" : "23",

    login : {
        lostButton : 'Réinitialiser le mot de passe',
        gotoLogin : 'Retour à la connexion',
        gotoLost : 'Mot de passe perdu?',
        lostExecuted : 'Un mot de passe temporaire a été envoyé à votre adresse email.'
      },
    'resources.Dashboard' : 'Dashboard',
    'resources.User' : 'Riders',
    'resources.Ride' : 'Rides',
    'resources.Vehicle' : 'Véhicules',
    'resources.Parking' : 'Parking',
    'resources.Rank' : 'Top 10',
    'resources.Space' : 'Espaces',
    'resources.Administrator' : 'Administrateurs',
    'resources.UserAdmin' : 'Utilisateurs',
    'resources.Calendar'  : 'Calendrier',
    resources : {
        User : {
            fields : {
                'spaces[0].name' : 'Espace'
            }
        },
        Vehicle : {
            fields : {
                'name'            : 'Nom du véhicule',
                'description'     : 'Description du véhicule',
                'parking.name'    : 'Parking',
                'serialNumber'    : 'Numéro de série',
                'frameNumber'     : 'Numéro du Cadre',
                'insuranceNumber' : 'Numéro d\'assurance',
                'type'            : 'Type',
                'lock.type'       : 'Type',
                'lock.code'       : 'Linka code',
                'lock.macAddress' : 'Linka mac address',
                'enabled'         : 'Disponible'
            }
        },
        Ride : {
            fields : {
                'spaces[0].name' : 'Espace',
                'user.email' : 'Email',
                'date'       : 'Date',
                'time.start' : 'Début',
                'time.end'   : 'Fin'
            }
        },
        Parking : {
            'vehicleCreateTitle' : 'Nouveau véhicule',
            'vehicleEditTitle' : 'Modifier les informations du véhicule',
            'vehicleDeleteTitle' : 'Suppression du véhicule',
            fields : {
                'name' : 'Nom du parking',
                'address.formattedAddress' : 'Adresse'
            }
        },
        Rank : {

        },
        Space : {
            'field' : {
                'name' : 'Nom de l\'espace',
                'address.formattedAddress' : 'Adresse'
            }
        },
        Administrator : {
            'fields' : {
                'active' : 'Actif'
            }
        }
    }

}



export default customMessages;