import { DataProvider} from 'ra-core';
import { fetchUtils } from 'react-admin';

import config from './../configprovider';
import createDefaultDataProvider from './default';

const httpClient = fetchUtils.fetchJson;

const defaultDataProvider : DataProvider = createDefaultDataProvider(config('ACCOUNTS_API_URL'));

/*
  Utilities function to convert accounts meta field to a map and vice-versa
*/
const metaToMap = (meta : Meta[]) =>
  (meta || []).reduce(
    (cumul : MetaMap, next : Meta) => {
      cumul[next.key] = next.value;
      return cumul;
    },
    {}
  );

const mapTopMeta = (map : MetaMap) =>
  Object.entries(map || {}).map(([key, value]) => ({key, value}));

export const apiObjectToFront = ({meta, ...rest} : any) : any => ({
  meta : metaToMap(meta),
  ...rest
});

export const frontObjectToApi = ({meta, ...rest} : any) : any => ({
  meta : mapTopMeta(meta),
  ...rest
});

export const accountsDataProvider : DataProvider = {
  ...defaultDataProvider,

  getList : async (resource, params) => {
    const {data, ...rest} = await defaultDataProvider.getList(resource, params);

    return {
      data : data.map(item => apiObjectToFront(item)),
      ...rest
    };
  },

  getOne : async (resource, params) => {
    const {data, ...rest} = await defaultDataProvider.getOne(resource, params);

    return {
      data : apiObjectToFront(data),
      ...rest
    };
  },

  getMany : async (resource, params) => {
    const {data, ...rest} = await defaultDataProvider.getMany(resource, params);

    return {
      data : data.map(item => apiObjectToFront(item)),
      ...rest
    };
  },

  getManyReference : async (resource, params) => {
    const {data, ...rest} = await defaultDataProvider.getManyReference(resource, params);

    return {
      data : data.map(item => apiObjectToFront(item)),
      ...rest
    };
  },

  create : async (resource, params) =>
    httpClient(`${config('ACCOUNTS_API_URL')}/invitations`, {
      method: 'POST',
      body: JSON.stringify(frontObjectToApi(params.data)),
      user: { authenticated: true, token : `Bearer ${localStorage.getItem('token')}` }
    }).then(({ json } : any) => ({
        data: { ...params.data, id: json.id },
    })),

  update : async (resource, params) => {
    const {data, ...rest} =  await defaultDataProvider.update(resource, {
      ...params,
      data : frontObjectToApi(params.data)
    });

    return {
      data : apiObjectToFront(data),
      ...rest
    };
  }
};


type Meta = {
  key : string,
  value : string
};

type MetaMap = {
  [key : string] : string
}