import { Record } from "ra-core";
import { ACTION_PREFIX } from '../../shared/constants/redux';

export const ADMIN_LOGIN = `@@starbolt/admin-login`;
export const USER_LOGIN = `@@starbolt/user-login`;
// export const LOGOUT = `@@starbolt/logout`;
export const RESOURCES_LOADED = `@starbolt/resources-loaded`;
export const SPACE_SELECTED = `@starbolt/space-selected`;
export const PARKINGS_SELECTED = `@starbolt/parkings-selected`

export const LOGIN = `${ACTION_PREFIX}/login`;
export const LOGOUT = `${ACTION_PREFIX}/logout`;
export const ACCOUNT_UPDATED = `${ACTION_PREFIX}/account-updated`;

export const login = (account : Record) => ({type : LOGIN, payload : account});
export const logout = () => ({type : LOGOUT});
export const accountUpdated = (profile : Profile) => ({type : ACCOUNT_UPDATED, payload : profile});

export const adminLogin = (account:any) => ({type : ADMIN_LOGIN, payload : account});
export const userLogin = (account:any) => ({type : USER_LOGIN, payload : account});

export const resourceLoaded = (resources:any) => ({type : RESOURCES_LOADED, payload : resources});
export const spaceSelected = (id:any) => ({type : SPACE_SELECTED, payload : id});
export const parkingsSelected = (ids:any) => ({type : PARKINGS_SELECTED, payload : ids});

export interface Profile {
  firstName : string,
  lastName  : string,
}
