import styled from "styled-components";
import { Row, Col } from "react-flexbox-grid";

export const RowPadded = styled(Row)`
  margin-bottom: 16px;
  margin-top: 16px;
`;

export const ColPadded = styled(Col)`
  margin-bottom: 16px;
  margin-top: 16px;
`;

export const ChartContainer = styled.div`
  border: 1px solid rgba(0, 0, 0, 0.3);
  height: 40vh;
  display: flex;
`;
