import React, { FC } from 'react';
import { List, Datagrid, TextField, DateField, ReferenceField, ListProps } from 'react-admin';
import { useSelector } from 'react-redux';
import { StarboltState } from '../../types';


const ParkingsList: FC<ListProps> = props => {
  const state = useSelector((state : StarboltState) => state.starbolt);
  return (
    <List
      {...props}
      bulkActionButtons={false}
      title=""
      filter={{space : state.selectedSpace}}
      pagination={false}
    >
      <Datagrid rowClick="edit">
        <TextField source="name" />
        <TextField source="description" />
        <TextField source="address.formattedAddress" />
        {/* <TextField source="code" /> */}
      </Datagrid>
    </List>
  );

}


export default ParkingsList;