import React, { useState, FC} from 'react';
import { TextInput, Create, SimpleForm, TabbedForm, FormTab, BooleanInput, CreateProps } from 'react-admin';
import { useSelector } from 'react-redux';
import { StarboltState } from '../../types';

import Schedule from './components/Schedule';


const ParkingCreate: FC<CreateProps> = props => {
  const { selectedSpace } = useSelector((state : StarboltState) => state.starbolt);
    return (
        <Create {...props} transform={(data : any) => ({...data, space : selectedSpace})}>
            <TabbedForm redirect="list">
                <FormTab label='Informations'>
                    <TextInput source="name"/>
                    <TextInput source="description"/>
                    <TextInput source="address.formattedAddress"/>
                </FormTab>
                <FormTab label="Horaires ouverture">
                  <Schedule />
                </FormTab>
            </TabbedForm>
        </Create>
    )
}

export default ParkingCreate;