import { Admin, Resource } from "react-admin";
import { Provider } from "react-redux";

import "./App.css";

import dataProvider from "./providers/dataprovider";
import authProvider from "./providers/authprovider";
import i18nProvider from "./providers/i18nprovider";

import Login from "./layout/Login";
import Layout from "./layout/Layout";

import { createAdminStore } from "./store";
import history from "./history";

import { resourcesList } from "./resources";
import { Redirect } from "react-router";

function App() {
  return (
    <Provider
      store={createAdminStore({
        authProvider,
        dataProvider,
        history,
      })}
    >
      <Admin authProvider={authProvider} i18nProvider={i18nProvider} loginPage={Login} dataProvider={dataProvider} history={history} layout={Layout}>
        {(canI: any) =>
          !localStorage.getItem("token")
            ? // Redirect to login if the user is not logged, otherwise "canI" will
              // return false for each resource and the app will be stuck on the loading
              // page
              [<Redirect key='redirect' to='/login' />]
            : // Create a list of resource which depends on particular user permissions
              resourcesList.map(({ name, list, create, edit, options }) => {
                if (!canI("read", name)) {
                  return null;
                }

                const props = {
                  key: name,
                  name,
                  list: canI("list", name) ? list : undefined,
                  create: canI("create", name) ? create : undefined,
                  edit: canI("edit", name) ? edit : undefined,
                  options,
                };

                return <Resource {...props} />;
              })
        }
      </Admin>
    </Provider>
  );
}

export default App;
