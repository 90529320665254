import React, { useState, FC} from 'react';
import { TextInput, Create, SimpleForm, TabbedForm, FormTab, BooleanInput, CreateProps } from 'react-admin';
import { useSelector } from 'react-redux';

import Schedule from './components/Schedule';


const SpaceCreate: FC<CreateProps> = props => {
  const [daysChecked, setDaysChecked] : any = useState({});
    return (
        <Create {...props} >
            <TabbedForm redirect="list">
                <FormTab label='Informations'>
                    <TextInput source="name"/>
                    <TextInput source="description"/>
                    <TextInput source="code"/>
                    <TextInput source="address.formattedAddress"/>
                </FormTab>
                <FormTab label="Horaires">
                  <Schedule setDaysChecked={setDaysChecked} daysChecked={daysChecked} />
                </FormTab>
            </TabbedForm>
        </Create>
    )
}

export default SpaceCreate;