import {
    LOGOUT,
    RESOURCES_LOADED,
    SPACE_SELECTED,
    ADMIN_LOGIN,
    USER_LOGIN
  } from '../actions/account';
  
  const initialState = null;
  
  export default (state = initialState, action) => {
    switch(action.type) {
      case RESOURCES_LOADED: {        
          return action.payload.space
      }
      case SPACE_SELECTED: {
        return action.payload;
      }
      case LOGOUT: {
        return initialState;
      }
      default:
        return state;
    }
  };