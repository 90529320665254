import React, { FC } from 'react';
import { List, Datagrid, TextField, DateField, ReferenceField, useGetList, ListProps, FieldProps } from 'react-admin';
import { useSelector } from 'react-redux';
import { StarboltState } from '../../types';
import moment from 'moment';

const Begin: FC<FieldProps> = ({record}) => {
  return (
    <div>{moment(record?.time.start).format('HH:mm')}</div>
    )
}

const End: FC<FieldProps> = ({record}) => {
  return (
    <div>{moment(record?.time.end).format('HH:mm')}</div>
    )
}

const Time: FC<FieldProps> = props => {
  const { record } = props;
  const begin = moment(record?.time.start); 
  const end = moment(record?.time.end);
  const duration = moment.duration(end.diff(begin))
  const minutes = Math.floor(duration.minutes())
  const hours = Math.floor(duration.hours())
  return (
    <div>{hours}h:{minutes<10 ? `0${minutes}` : minutes}</div>
    )
}

const RidesList: FC<ListProps> = props => {

  const state = useSelector((state: StarboltState) => state.starbolt);
  const space = localStorage.getItem('space');

  Begin.defaultProps = { label : 'Début'};
  End.defaultProps = { label : 'Fin'};
  Time.defaultProps = { label : 'Temps du ride'}
  
  return (
    <List
      {...props}
      bulkActionButtons={false}
      title=""
      filter={{space : space ? space : state?.selectedSpace}}
    >
      <Datagrid>
        <TextField source="user.email"/>
        <DateField source="time.start" label="resources.Ride.fields.date"/>
        <Time />
        <Begin />
        <End />
        <TextField source="vehicleType" />
      </Datagrid>
    </List>
  );

}


export default RidesList;