import React, { useState, useEffect, FC } from 'react';
import styled from 'styled-components';
import { 
    SelectInput,
    FieldProps
} from 'react-admin';
import { Typography, Checkbox } from '@material-ui/core';
import { useForm } from 'react-final-form'
import { days, hours } from '../../../shared/constants/constants';

const Container = styled.div`
  margin-top : 40px;
`;

const Day = styled.div`
  font-weight : 600;
  font-family : sans-serif;
`;

const Row = styled.div`
  display        : flex;
  flex-direction : row;
`;

const TimeSpanContainer = styled.div`
  display        : flex;
  flex-direction : row;
  
`;

const MarginInput = styled.div`
  margin-right : 20px;
`;


const Schedule: FC<FieldProps> = props => {
    const { record } = props;
    let initialDaysChecked : any = {}
    days.forEach(({db}) => {
        initialDaysChecked[db] = record?.options?.days?.[db]?.startHour ? true : false;
    })
    const [daysChecked, setDaysChecked] = useState(initialDaysChecked)
    const form = useForm();

    const handleCheck = (day : any) => {
        if(daysChecked[day.db]){
            form.change(`options.days.${day.db}.startHour`, null)
            form.change(`options.days.${day.db}.endHour`, null)
        }
        else{
            form.change(`options.days.${day.db}.startHour`, 9)
            form.change(`options.days.${day.db}.endHour`, 18)
        }
        setDaysChecked({...daysChecked, [day.db] : !daysChecked[day.db]})
    }

    
    return (
        <>
        {
            days.map((day, index) =>{
                return (
                    <Container key={day.day}>
                        <Typography variant="h6">{day.day}</Typography>
                        <Row>
                        <TimeSpanContainer>
                            <Checkbox style={{ marginRight : '49px'}} checked={daysChecked[day.db]} onChange={()=>handleCheck(day)}/>
                            <Row>
                            <MarginInput>
                                <SelectInput 
                                    disabled={!daysChecked[day.db]} 
                                    choices={hours}
                                    source={`options.days.${day.db}.startHour`} 
                                    type="number" label="Ouvre à :" 
                                    InputLabelProps={{ shrink: true }}
                                    min={0}
                                    max={23}
                                />
                            </MarginInput>
                            <SelectInput 
                                disabled={!daysChecked[day.db]} 
                                choices={hours}
                                source={`options.days.${day.db}.endHour`} 
                                type="number" label="Ferme à :" 
                                InputLabelProps={{ shrink: true }}
                                min={0}
                                max={23}
                                required={daysChecked[day.db]}
                            />
                            </Row>
                        </TimeSpanContainer>
                        </Row>
                    </Container>
                )}
            )
        }
        </>
    )
}

export default Schedule;