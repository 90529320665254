import React from "react";
import { Col } from "react-flexbox-grid";
import User from "../entities/User";

interface Props {
  position: number;
  user: User;
  rideCount: number;
}

const PodiumCard = ({ position, user, rideCount }: Props) => {
  return (
    <Col style={{ border: "1px solid rgba(0, 0, 0, 0.3)", margin: "15px", textAlign : 'center'}} xs>
      <h2>{`${position + 1}${position + 1 === 1 ? "er" : "ème"}`}</h2>
      <h3>{user?.email}</h3>
      <h3>{rideCount}</h3>
    </Col>
  );
};

export default PodiumCard;