import React, { FC} from 'react';
import { FormTab, TextInput, required, SelectInput, BooleanInput, TabbedForm, FormTabProps } from 'react-admin';
import { VEHICLE_CHOICES } from '../../shared/constants/vehicletypes';

const VehicleForm: any = () => {
  return (
     <FormTab label="" value="">
        <TextInput source="name" label="resources.Vehicle.fields.name" validate={required()}/>
        <TextInput source="description" label="resources.Vehicle.fields.description"/>
        <SelectInput source="type" choices={VEHICLE_CHOICES} defaultValue="BIKE" label="resources.Vehicle.fields.type" />
        <BooleanInput source="enabled" defaultValue={true} label="resources.Vehicle.fields.enabled"/>
        <TextInput source="serialNumber" label="resources.Vehicle.fields.serialNumber"/>
        <TextInput source="frameNumber"label="resources.Vehicle.fields.frameNumber"/>
        <TextInput source="insuranceNumber" label="resources.Vehicle.fields.insuranceNumber"/>
        <TextInput source="lock.type" defaultValue="LINKA" disabled={true} label="resources.Vehicle.fields.lock.type"/>
        <TextInput source="lock.code" label="resources.Vehicle.fields.lock.code"/>
        <TextInput source="lock.macAddress" label="resources.Vehicle.fields.lock.macAddress"/>
    </FormTab>
  );
}

export default VehicleForm;