import React, { useEffect, useState, useMemo } from 'react';
import { useDataProvider } from 'react-admin';
import { useSelector, useDispatch } from 'react-redux';
import moment from 'moment';
// import useRidesCount from '../../shared/hooks/useRideCount';
import { RowPadded, ColPadded, ChartContainer } from "./style";
import { labels, options } from "../../shared/components/ChartData";
import Ride from '../../shared/entities/Ride';
import Card from '../../shared/components/Card';
import { cardData } from "../../shared/components/CardData";
import StarboltChart from '../../shared/components/Chart';
import { Grid, Col, Row } from "react-flexbox-grid";
import { spaceSelected } from '../../store/actions/account';
import { StarboltState } from '../../types';


interface Props {
    rideList: Ride[];
    loading?: boolean;
    chartData: number[];
    userCount: number;
    currentMonth: number;
    firstMonth: number;
    activeUsers: number;
    rideDurationAverage: number;
  }

  
  const calcRideByMonths = (
    rideCount: number,
    currentMonth: number,
    firstMonth: number
  ): string =>
    `${Math.round((rideCount / (currentMonth + 1 - firstMonth)) * 100) / 100}`;
  
  const sortBy = (a: Ride, b: Ride) =>
    new Date(b.time.end).getTime() - new Date(a.time.end).getTime();

    function getTimeDifference(ride: Ride) {
      return (
        new Date(ride.time.end).getTime() - new Date(ride.time.start).getTime()
      );
    }


function Dashboard(props : any){
  const currentMonth = new Date().getMonth();
  const currentYear = new Date().getFullYear();

  const [firstMonth, setFirstMonth] = useState(0);
  const [rideList, setRideList]  = useState([]);
  const [activeUsers, setActiveUsers] : any  = useState([]);
  const [users, setUsers] : any  = useState([]);
  const [rideDurationAverage, setRideDurationAverage] = useState(0);
  const [rideByMonth, setRideByMonth] : any = useState(0);
  const [chartData, setChartData] : any = useState();
  const [space, setSpace] : any = useState('');

  const state = useSelector((state : StarboltState) => state.starbolt);
  const dispatch = useDispatch();
  const dataProvider = useDataProvider();
  
  useEffect(()=>{
    setSpace(state.selectedSpace);
    //load dasboard data
    if(space !== state.selectedSpace){
      dataProvider.getList('Ride', {
        pagination : { page : 1, perPage : 100000}, 
        sort : {field : 'time.end', order : 'DESC'}, 
        filter : { space : state.selectedSpace}})
        .then( ({data} : any) => { 
          setRideList(data);
          const activeIds = data.map(
            (ride : any) => ride.user && ride.user.id
          );
          const ids = new Set(activeIds);
          setActiveUsers(Array.from(ids).length);
          let sum = 0;
          data.forEach((ride : any) => sum += getTimeDifference(ride));
          setRideDurationAverage(sum/data.length)
  
          const lastRide = [...data].shift();
          const firstRide = [...data].pop();
          const nbMonths = Math.ceil(moment(lastRide.time.end).diff(moment(firstRide.time.end), 'months', true));
  
          const firstMonth = currentMonth === 0 ? 11 : currentMonth - 1;
          setFirstMonth(firstMonth);
          setRideByMonth(Math.round(data.length/nbMonths))  
        })
      dataProvider.getList('User', {
        pagination : { page : 1, perPage : 1000}, 
        sort : {field : '', order : ''}, 
        filter : { spaces : state.selectedSpace}})
        .then(({data} : any) =>{
          setUsers(data.length);
        })
    }
  }, [state])

  const barChartDataset = useMemo(() => {
    const months: any = {};
    
    rideList.forEach((ride : any) => {
      const year = new Date(ride?.time.end).getFullYear();
      const month = new Date(ride?.time.end).getMonth();
  
      if((year === currentYear) || (currentMonth === 0 && year === currentYear-1 && month === 11)){
        months[month] = months[month] ? months[month]+1 : 1;
      }
    });
    if(currentMonth === 0){
      return Object.values(months).reverse() as number[];
    } else {
      return Object.values(months) as number[];
    }
  }, [rideList, currentMonth, firstMonth]);

  const cards = useMemo(
    () =>
      cardData({
        rideList,
        activeUsers,
        users,
        rideDurationAverage,
        rideByMonth
      }),
    [rideList, activeUsers, users, rideDurationAverage, rideByMonth]
  );

  return (
    <Grid>
      <Row>
        <h1>Dashboard</h1>
      </Row>
      <RowPadded middle="xs">
          {
          cards.map((card, index) => (
              <ColPadded key={index} xs={12} sm={12} md={6} lg={6} xl>
                  <Card {...card} />
              </ColPadded>
          ))
          }
      </RowPadded>
      <RowPadded  middle="xs">
        <Col xs>
          <ChartContainer>
            <StarboltChart
              labels={[labels[firstMonth], labels[currentMonth]]}
              datasets={[
                {
                  fill: false,
                  data: barChartDataset,
                  label: "Rides",
                  backgroundColor: "#272744",
                  borderColor: "#ffe604",
                },
              ]}
              options={options}
              
            />
          </ChartContainer>
        </Col>
      </RowPadded>
    </Grid>
  )
}

export default Dashboard;
