import React, { useRef, useEffect } from "react";
import Chart, { ChartDataSets, ChartData, ChartOptions } from "chart.js";
import Spinner from "./Spinner";

interface Props {
  datasets: ChartDataSets[];
  labels: ChartData["labels"];
  options?: ChartOptions,
  loading?: boolean;
}

const StarboltChart = ({ datasets, labels, options, loading }: Props) => {
  const chartRef = useRef<HTMLCanvasElement>(null);

  useEffect(() => {
    if (chartRef.current) {
      new Chart(chartRef.current, {
        type: "line",
        data: {
          datasets,
          labels,
        },
        options: {
          maintainAspectRatio: false,
          ...options,
        },
      });
    }
  }, [datasets, labels, options]);

  if (loading) return <Spinner size="lg" />

  return <canvas ref={chartRef} />;
};

export default StarboltChart;