import Ride from "../entities/Ride";
import TimeLineSvg   from '../../assets/image/time-line.svg' 
import HeartLineSvg from '../../assets/image/hearts-line.svg'
import UserLineSvg from '../../assets/image/user-line.svg'
import BarChartSvg from '../../assets/image/bar-chart-2-fill.svg'
import RidIngLineSvg from '../../assets/image/riding-line.svg'

// type Datas = {
//   rideList: Ride[],
//   userCount: number,
//   activeUsers: number,
//   rideByMonth: string,
//   rideDurationAverage: number,
// }

function formatTimeData(data: number) {
  if (data.toString().length === 1) return "0" + data;
  return data;
}

function msToTime(duration: number) {
  let seconds = Math.floor((duration / 1000) % 60),
    minutes = Math.floor((duration / (1000 * 60)) % 60),
    hours = Math.floor((duration / (1000 * 60 * 60)) % 24);

  hours = hours < 10 ? 0 + hours : hours;
  minutes = minutes < 10 ? 0 + minutes : minutes;
  seconds = seconds < 10 ? 0 + seconds : seconds;

  return (
    formatTimeData(hours) +
    ":" +
    formatTimeData(minutes) +
    ":" +
    formatTimeData(seconds)
  );
}


export const cardData = ({ users, rideList, activeUsers, rideByMonth, rideDurationAverage }: any ) => [
  {
    title: "Nombre de rides",
    iconPath: RidIngLineSvg,
    data: rideList.length,
  },
  {
    title: "Utilisateur Actifs",
    iconPath: HeartLineSvg,
    data: activeUsers,
  },
  {
    title: "Utilisateurs inscrits",
    iconPath: UserLineSvg,
    data: users,
  },
  {
    title: "Rides par mois",
    iconPath: BarChartSvg,
    data: rideByMonth,
  },
  {
    title: "Durée par ride",
    iconPath: TimeLineSvg,
    data: msToTime(rideDurationAverage),
  },
];
