import { ChartOptions } from "chart.js";

export const labels = [
  "Janvier",
  "Février",
  "Mars",
  "Avril",
  "Mai",
  "Juin",
  "Juillet",
  "Août",
  "Septembre",
  "Octobre",
  "Novembre",
  "Décembre",
];

export const options: ChartOptions = {
  title: {
    display: true,
    text: "Nombre de rides par mois",
    position: "top",
    fontSize: 24,
    padding: 20,
  },
  scales: {
    yAxes: [
      {
        ticks: {
          stepSize: 2,
          beginAtZero: true,
        },
      },
    ],
  },
};