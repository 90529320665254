import { combineReducers } from 'redux';

import selectedSpaceReducer from './selectedSpace';
import accountReducer from './account';
import parkingReducer from './parkings';
import themeReducer   from './theme';
 
export default combineReducers({
  selectedSpace : selectedSpaceReducer,
  parkings : parkingReducer,
  account : accountReducer,
  theme : themeReducer
});