import {
    THEME_UPDATED,
  } from '../actions/theme';
import { LOGOUT, RESOURCES_LOADED } from '../actions/account';
  
  const initialState = null;
  
  export default (state = initialState, action) => {
    switch(action.type) {
      case THEME_UPDATED: {        
          return action.payload
      }
      case RESOURCES_LOADED: {   
        return action.payload.theme
      }
      case LOGOUT: {
        return initialState;
      }
      default:
        return state;
    }
  };