import polyglotI18nProvider from 'ra-i18n-polyglot';

// import englishMessages from '../i18n/en';
import frenchMessages from '../i18n/fr';

const i18nProvider = polyglotI18nProvider(locale => {
    if (locale === 'fr') {
        return frenchMessages;
    }

    // Always fallback on french
    return frenchMessages;
}, 'fr');

export default i18nProvider;
