import React, { ReactElement } from "react";
import { Resource } from "react-admin";
import styled from "styled-components";

import {
  Home,
  People,
  Dashboard as DashboardIcon,
  DirectionsBike,
  List,
  SupervisorAccount,
  AccountTree,
  Motorcycle,
  LocalParking,
  CalendarToday,
} from "@material-ui/icons";
// import RideSvg from './assets/image/riding-line.svg';
// import Bike from './assets/image/riding-line.svg';
// import List from './assets/image/rank.png';
import RankSvg from "./assets/image/rank.png";

import VehiclesList from "./components/vehicles/VehiclesList";
import VehicleCreate from "./components/vehicles/VehicleCreate";
import VehicleEdit from "./components/vehicles/VehicleEdit";

import ParkingsList from "./components/parkings/ParkingsList";
import ParkingCreate from "./components/parkings/ParkingCreate";
import ParkingEdit from "./components/parkings/ParkingEdit";

import SpacesList from "./components/spaces/SpacesList";
import SpaceCreate from "./components/spaces/SpaceCreate";
import SpaceEdit from "./components/spaces/SpaceEdit";

import AdminList from "./components/administrators/AdminList";
import AdminEdit from "./components/administrators/AdminEdit";

import UsersList from "./components/users/UsersList";
import UsersAdminList from "./components/users/UsersAdminList";
import RidesList from "./components/rides/RidesList";
import Dashboard from "./components/dashboard/Dashboard";
import Calendar from "./components/calendar/Calendar";

import RankingList from "./components/ranking/RankingList";

const Icon = styled.img`
  width: 20px;
  filter: opacity(0.6);
`;

interface RAViewProps {
  [key: string]: any;
}

type RAResourceType = typeof Resource;
export type BranchType = {
  __type?: "branch";
  name: string;
  label?: string;
  icon?: ReactElement;
  children: ResourceType[];
};

interface ResourceLeaf {
  __type: "resource";
  __hidden?: boolean;
  name: string;
  label?: string;
  icon?: ReactElement;
  list?: React.FC<RAViewProps>;
  create?: React.FC<RAViewProps>;
  edit?: React.FC<RAViewProps>;
  options?: { label?: string };
}

interface DashboardLeaf {
  __type: "dashboard";
  name: string;
  label?: string;
  icon?: ReactElement;
}

export type LeafType = ResourceLeaf | DashboardLeaf;
export type ResourceType = BranchType | LeafType;
export type ResourcesDefinition = ResourceType[];

export const resourcesUserAdmin: any = [
  {
    __type: "resource",
    name: "Dashboard",
    icon: <DashboardIcon />,
    resource: <Resource name='Dashboard' list={Dashboard} />,
  },
  {
    __type: "resource",
    name: "User",
    icon: <People />,
    resource: <Resource name='User' list={UsersList} />,
  },
  {
    __type: "resource",
    name: "Rank",
    icon: <Icon src={RankSvg} />,
    resource: <Resource name='Rank' list={RankingList} />,
  },
  {
    __type: "resource",
    name: "Ride",
    icon: <DirectionsBike />,
    resource: <Resource name='Ride' list={RidesList} />,
  },
  {
    __type: "resource",
    name: "Vehicle",
    icon: <Motorcycle />,
    resource: <Resource name='Vehicle' list={VehiclesList} /* create={VehicleCreate} edit={VehicleEdit} */ />,
  },
  {
    __type: "resource",
    name: "Parking",
    icon: <LocalParking />,
    resource: <Resource name='Parking' list={ParkingsList} create={ParkingCreate} edit={ParkingEdit} />,
  },
  {
    __type: "resource",
    name: "Calendar",
    icon: <CalendarToday />,
    resource: <Resource name='Calendar' list={Calendar} />,
  },
];

export const resourcesUser: any = [
  {
    __type: "resource",
    name: "Dashboard",
    icon: <DashboardIcon />,
    resource: <Resource name='Dashboard' list={Dashboard} />,
  },
  {
    __type: "resource",
    name: "User",
    icon: <People />,
    resource: <Resource name='User' list={UsersList} />,
  },
  {
    __type: "resource",
    name: "Rank",
    icon: <Icon src={RankSvg} />,
    resource: <Resource name='Rank' list={RankingList} />,
  },
  {
    __type: "resource",
    name: "Vehicle",
    icon: <Motorcycle />,
    resource: <Resource name='Vehicle' list={VehiclesList} /* create={VehicleCreate} edit={VehicleEdit} */ />,
  },
  {
    __type: "resource",
    name: "Ride",
    icon: <DirectionsBike />,
    resource: <Resource name='Ride' list={RidesList} />,
  },
  {
    __type: "resource",
    name: "Calendar",
    icon: <CalendarToday />,
    resource: <Resource name='Calendar' list={Calendar} />,
  },
];

export const resourcesAdmin: any = [
  {
    __type: "resource",
    name: "Space",
    icon: <AccountTree />,
    resource: <Resource name='Space' list={SpacesList} create={SpaceCreate} edit={SpaceEdit} />,
  },
  {
    __type: "resource",
    name: "Administrator",
    icon: <SupervisorAccount />,
    resource: <Resource name='Administrator' list={AdminList} /*  edit={AdminEdit} */ />,
  },
  {
    __type: "resource",
    name: "UserAdmin",
    icon: <People />,
    resource: <Resource name='UserAdmin' list={UsersAdminList} />,
  },
  {
    __type: "resource",
    name: "Vehicle",
    icon: <Motorcycle />,
    resource: <Resource name='Vehicle' list={VehiclesList} /* create={VehicleCreate} edit={VehicleEdit} */ />,
  },
];

export const resources: ResourcesDefinition = [
  {
    __type: "resource",
    name: "Dashboard",
    icon: <DashboardIcon />,
    list: Dashboard,
  },
  {
    __type: "resource",
    name: "User",
    icon: <People />,
    list: UsersList,
  },
  {
    __type: "resource",
    name: "Rank",
    icon: <Icon />,
    list: RankingList,
  },
  {
    __type: "resource",
    name: "Ride",
    icon: <DirectionsBike />,
    list: RidesList,
  },
  {
    __type: "resource",
    name: "Vehicle",
    icon: <List />,
    list: VehiclesList,
    create: VehicleCreate,
    edit: VehicleEdit
  },
  {
    __type: "resource",
    name: "Parking",
    icon: <LocalParking />,
    list: ParkingsList,
    create: ParkingCreate,
    edit: ParkingEdit,
  },
  {
    __type: "resource",
    name: "Space",
    icon: <List />,
    list: SpacesList,
    create: SpaceCreate,
    edit: SpaceEdit,
  },
  {
    __type: "resource",
    name: "Administrator",
    icon: <SupervisorAccount />,
    list: AdminList,
  },
  {
    __type: "resource",
    name: "UserAdmin",
    icon: <People />,
    list: UsersAdminList,
  },
  {
    __type: "resource",
    name: "Calendar",
    icon: <CalendarToday />,
    list: Calendar,
  },
];

export const isLeaf = (node: ResourceType) => !!node.__type && node.__type !== "branch";
export const isBranch = (node: ResourceType) => !isLeaf(node);
export const isHidden = (node: ResourceType) => node.__type === "resource" && !!node.__hidden;

function traverseResource<T>(group: ResourceType[], cumul: T[] = [], callback: (node: ResourceLeaf) => T) {
  group.forEach((node) => {
    if (isLeaf(node) && node.__type === "resource") {
      cumul.push(callback(node));
    } else if (isBranch(node)) {
      traverseResource((node as BranchType).children, cumul, callback);
    }
  });

  return cumul;
}

export const resourcesList = traverseResource<ResourceLeaf>(resources, [], (node: ResourceLeaf) => node);

export const resourceNames = traverseResource<string>(resources, [], (node: LeafType) => (node.__type === "resource" ? node.name : "")).filter(
  (n) => !!n
);
