import React from 'react';
import styled from 'styled-components';
import randomColor from 'randomcolor';

const Group = ({ group } : any) => {
  
    return (
        <Container>
            <Left>{group.title}</Left>
            <Middle>{group.type}</Middle>
            <Right>Status</Right>
        </Container>
    );
}

export default Group;

const Container = styled.div`
  display : flex;
  flex-direction : row;
`;

const Left = styled.div`
    flex : 1;
    border-right : 1px solid #bbb;
    justify-content : center;
    align-items : center;
    text-align : center;
`;
const Middle = styled.div`
    flex : 1;
    border-right : 1px solid #bbb;
    justify-content : center;
    align-items : center;
    text-align : center;
`;

const Right = styled.div`
    flex : 1;
    justify-content : center;
    align-items : center;
    text-align : center;
`;
