import React, { FC } from "react";
import { List, Datagrid, TextField, DateField, ReferenceField, ListProps } from "react-admin";
import { useSelector } from "react-redux";

const VehiclesList: FC<ListProps> = (props) => {
  const permissions = localStorage.getItem("permissions");

  const state = useSelector((state: any) => state.starbolt);
  const isAdmin = permissions === "Admin";
  const isAdminView = state.theme === "Admin";
  return (
    <List {...props} bulkActionButtons={false} title='' filter={isAdmin && isAdminView ? {} : { parking: state.parkings[0] }} pagination={false}>
      <Datagrid>
        <TextField source='type' />
        <TextField source='name' />
        <ReferenceField label='Parking' source='parking' reference='Parking'>
          <TextField source='name' />
        </ReferenceField>
        <TextField source='frameNumber' />
        {isAdmin && isAdminView && <TextField source='serialNumber' />}
        <TextField source='enabled' />
        {isAdmin && isAdminView && <TextField source='lock.type' />}
        {isAdmin && isAdminView && <TextField source='lock.code' />}
        {isAdmin && isAdminView && <TextField source='lock.macAddress' />}
      </Datagrid>
    </List>
  );
};

export default VehiclesList;
