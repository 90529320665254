import styled, { keyframes } from "styled-components";

interface Props {
  size?: "xs" | "md" | "lg"
};

const rotate360 = keyframes`
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
`;

const Spinner = styled.div<Props>`
  animation: ${rotate360} 1s linear infinite;
  transform: translateZ(0);
  border-top: 2px solid #5e7ef3;
  border-right: 2px solid #5e7ef3;
  border-bottom: 2px solid #5e7ef3;
  border-left: 2px solid #082db3;
  background: transparent;
  width: ${({ size = "xs", theme }) => theme.sizes[size]};
  height: ${({ size = "xs", theme }) => theme.sizes[size]};
  border-radius: 50%;
  margin: auto;
`;

export default Spinner;