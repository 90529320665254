import React from 'react';
import styled from 'styled-components';
import randomColor from 'randomcolor';

const Item = ({ item, timelineContext, itemContext, getItemProps, getResizeProps } : any) => {
    const backgroundColor = item.bgColor;
    const borderColor = item.bgColor;
    
    return (
        <div
        {...getItemProps({
          style: {
            backgroundColor,
            color: 'white',
            borderColor,
            borderStyle: "solid",
            borderWidth: 0,
            borderRadius: 4,
            overflow : 'hidden'
          }
        })}
        >
          <Content>
            <div
            style={{
                height: itemContext.dimensions.height,
                overflow: "hidden",
                paddingLeft: 3,
                textOverflow: "ellipsis",
                whiteSpace: "nowrap"
            }}
            >
            {itemContext.title}
            </div>
            <div style={{paddingLeft : '3px'}}>Status</div>
          </Content>
        </div>
    );
}

export default Item;

const Content = styled.div`
  display : flex;
  flex-direction : column;
`;