import { ROOT, ADMIN } from "../shared/constants/roles";
import { resourceNames } from "../resources";

const PERMISSIONS: {
  [resource: string]: {
    [action: string]: {
      [role: string]: boolean;
    };
  };
} = {};

[...resourceNames].forEach((resource: string) => {
  PERMISSIONS[resource] = {
    read: { [ROOT]: true, [ADMIN]: true },
    list: { [ROOT]: true, [ADMIN]: true },
    create: { [ROOT]: true, [ADMIN]: true },
    edit: { [ROOT]: true, [ADMIN]: true },
    delete: { [ROOT]: true, [ADMIN]: true },
  };
});

const addRoleToPermissions = (resource: string, actions: string[] | string, role: string) => {
  (Array.isArray(actions) ? actions : [actions]).forEach((action) => {
    PERMISSIONS[resource][action][role] = true;
  });
};

// Admin resources
addRoleToPermissions("Dashboard", ["read", "list", "create", "edit"], "Admin");
addRoleToPermissions("User", ["read", "list", "create", "edit"], "Admin");
addRoleToPermissions("Rank", ["read", "list", "create", "edit"], "Admin");
addRoleToPermissions("Ride", ["read", "list", "create", "edit"], "Admin");
addRoleToPermissions("Vehicle", ["read", "list", "create", "edit"], "Admin");
addRoleToPermissions("Parking", ["read", "list", "create", "edit"], "Admin");
addRoleToPermissions("Space", ["read", "list", "create", "edit"], "Admin");
addRoleToPermissions("Administrator", ["read", "list", "create", "edit"], "Admin");
addRoleToPermissions("Calendar", ["read", "list", "create", "edit"], "Admin");
addRoleToPermissions("UserAdmin", ["read", "list", "create", "edit"], "Admin");

// UserAdmin resources
addRoleToPermissions("Dashboard", ["read", "list"], "UserAdmin");
addRoleToPermissions("User", ["read", "list"], "UserAdmin");
addRoleToPermissions("Rank", ["read", "list"], "UserAdmin");
addRoleToPermissions("Ride", ["read", "list"], "UserAdmin");
addRoleToPermissions("Vehicle", ["read", "list"], "UserAdmin");
addRoleToPermissions("Parking", ["read", "list", "create", "edit"], "UserAdmin");
addRoleToPermissions("Calendar", ["read", "list"], "UserAdmin");

addRoleToPermissions("Dashboard", ["read", "list"], "User");
addRoleToPermissions("User", ["read", "list"], "User");
addRoleToPermissions("Rank", ["read", "list"], "User");
addRoleToPermissions("Ride", ["read", "list"], "User");
addRoleToPermissions("Vehicle", ["read", "list"], "User");
addRoleToPermissions("Calendar", ["read", "list"], "User");

const canI =
  (account: { roles: string[] }) =>
  (action: string, resource: string): boolean => {
    const { roles = [] } = account;
    const permittedRoles = PERMISSIONS[resource]?.[action] || {};
    return roles.some((role) => !!permittedRoles[role]);
  };

export default canI;
